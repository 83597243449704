// @flow
import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import {
  Subtitle,
  Title,
  DescriptionView,
  Description,
  DescriptionText,
  DescriptionTitleGroup,
} from '../components/styles'
import '../css/main.min.css'

type Props = {
  data: Object,
}

export default class Index extends React.Component<Props> {
  render() {
    return (
      <Layout data={this.props.data}>
        <DescriptionView>
          <DescriptionTitleGroup>
            <Subtitle>Welcome To</Subtitle>
            <Title>Progression at Kwara 🎉</Title>
          </DescriptionTitleGroup>
          <Description>
            <DescriptionText>
              The progression framework is a tool that helps Kwarans and their
              managers:
            </DescriptionText>
            <DescriptionText>
              1. make development and career plans
              <br />
              2. talk about what we’re looking for in a consistent way
              <br />
              3. set a fair level of compensation.
            </DescriptionText>
            <DescriptionText>
              The framework is a compass, not a GPS. It’s meant to be helpful.
              It’s not meant to be a rating system for humans, free from edge
              cases.
            </DescriptionText>
            <Subtitle>How does it work?</Subtitle>
            <DescriptionText>
              The framework covers all the things we’re looking for from team
              members at Kwara. We’re interested in these five elements:
            </DescriptionText>
            <DescriptionText>
              <strong>Mastery</strong> - Your Kwara knowledge and technical
              capability
            </DescriptionText>
            <DescriptionText>
              <strong>Impact</strong> - The size, scope and value of what you
              deliver
            </DescriptionText>
            <DescriptionText>
              <strong>Comms &amp; Feedback</strong> - How you interact with
              others
            </DescriptionText>
            <DescriptionText>
              <strong>Leadership</strong> - How people around you become better
              and more impactful
            </DescriptionText>
            <DescriptionText>
              We sort them into six levels, and we try to give specific examples
              of behaviours we expect for each. Each of those levels has a
              fairly wide salary range associated with it, and within each level
              you can progress in sub-levels. So even if you’re at level 3 for a
              couple of years, you’ll still be able to see that you’re moving
              forward. Basically, the more behaviours you show from your level,
              the more you’ll progress.
            </DescriptionText>
            <DescriptionText>
              Your manager will work with you on this. None of it will happen
              mysteriously behind closed doors. You’ll agree what level of
              progression you’re going for and what you need to improve on with
              your manager. It should be clear how you’re doing relative to that
              at all times.
            </DescriptionText>
            <Subtitle>Things to keep in mind</Subtitle>
            <DescriptionText>
              There are many different ways to progress and be valuable to Kwara
              as you grow, including deep technical knowledge and ability,
              technical leadership and people management. All are equally
              valuable paths in Kwara’s team.
            </DescriptionText>
            <DescriptionText>
              The framework represents a career’s worth of progression, people
              shouldn’t expect to fly up it in 18 months!
            </DescriptionText>
            <DescriptionText>
              Progression isn’t an exact science and there will always be some
              ambiguity.
            </DescriptionText>
            <DescriptionText>
              This isn’t a checklist – it’s possible to progress up a level
              without showing all the behaviours in that level.
            </DescriptionText>
            <DescriptionText>
              There will be levels on top (eg ‘Inventor of Android’ or ‘Author
              of Go’), but we won’t add them until we need them.
            </DescriptionText>
            <DescriptionText>
              The levels do not directly correlate with your job title. It can
              be that you have a similar job title as others, but are on a
              higher or lower level of progression due to experience or growth
              you’ve gone through
            </DescriptionText>
            <Subtitle>Give us your feedback!</Subtitle>
            <DescriptionText>
              This is only the first version of our framework and we really want
              your feedback. We’re particularly keen to add as many examples to
              the behaviours as possible, to further clarify them.
            </DescriptionText>
            <DescriptionText>
              Take a look around and let us know what you think! 🚀
            </DescriptionText>
          </Description>
        </DescriptionView>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            path
            sidebarTitle
            sidebarGroup
          }
        }
      }
    }
  }
`
